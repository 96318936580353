// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, onSnapshot } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCj8VxZQlV7HE_nXyg2iAKERjaL99_MXXs",
  authDomain: "vgicollect-66ae0.firebaseapp.com",
  databaseURL: "https://vgicollect-66ae0.firebaseio.com",
  projectId: "vgicollect-66ae0",
  storageBucket: "vgicollect-66ae0.firebasestorage.app",
  messagingSenderId: "58130877429",
  appId: "1:58130877429:web:887e7749aa74c783bbaebc"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {db}