import React, { useEffect, useRef, useState } from 'react';
import { useClickStore } from '../store/clickStore';
import { useShallow } from 'zustand/react/shallow';

export default function Measurements() {
  const [showDropdown, setShowDropdown] = useState(false); // Control dropdown visibility
  const ref = useRef();

  const { clickType, setClickType, setZoomToExtent, setLabels, showLabels } = useClickStore(
    useShallow((state) => ({
      clickType: state.clickType,
      setClickType: state.setClickType,
      setZoomToExtent: state.setZoomToExtent,
      setLabels: state.setLabels,
      showLabels: state.showLabels,
    }))
  );

  useEffect(() => {
    console.log('click type changed', clickType);

    const cursorStyles = {
      pan: 'pointer',
      info: 'help',
      distance: 'crosshair',
      zoom: 'zoom-in',
      color: 'move',
    };
    document.body.style.cursor = cursorStyles[clickType] || 'default';

    return () => {
      document.body.style.cursor = 'default';
    };
  }, [clickType]);

  const handleButtonClick = (id) => {
    setClickType(id);
    setShowDropdown(false); // Close dropdown on selection
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="absolute top-28 right-4" ref={ref}>
      {/* Toggle Button */}
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="bg-gray-500 text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-gray-600"
      >
        🔧 {/* Gear icon (spanner) */}
      </button>

      {/* Dropdown Menu */}
      {showDropdown && (
        <div className="absolute right-0 mt-2 bg-white p-1 rounded-lg shadow-lg flex flex-col items-center space-y-2 z-50">
          <button
            onClick={() => setZoomToExtent(true)}
            className="bg-black w-6 h-6 rounded-full flex items-center justify-center text-sm hover:bg-gray-800"
          >
            E
          </button>
          <button
            onClick={() => handleButtonClick('pan')}
            className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
              clickType === 'pan' ? 'bg-blue-600 text-white' : 'bg-black'
            } hover:bg-gray-300`}
          >
            P
          </button>
          <button
            onClick={() => handleButtonClick('info')}
            className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
              clickType === 'info' ? 'bg-blue-600 text-white' : 'bg-black'
            } hover:bg-gray-300`}
          >
            I
          </button>
          <button
            onClick={() => handleButtonClick('distance')}
            className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
              clickType === 'distance' ? 'bg-blue-600 text-white' : 'bg-black'
            } hover:bg-gray-300`}
          >
            D
          </button>
          <button
            onClick={() => handleButtonClick('zoom')}
            className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
              clickType === 'zoom' ? 'bg-blue-600 text-white' : 'bg-black'
            } hover:bg-gray-300`}
          >
            Z
          </button>
          <button
            onClick={() => handleButtonClick('color')}
            className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
              clickType === 'color' ? 'bg-blue-600 text-white' : 'bg-black'
            } hover:bg-gray-300`}
          >
            C
          </button>
          <button
            onClick={() => setLabels()}
            className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
              showLabels ? 'bg-blue-600 text-white' : 'bg-black'
            } hover:bg-gray-300`}
          >
            L
          </button>
        </div>
      )}
    </div>
  );
}
