import React, { useEffect, useState } from 'react'
import { useClickStore } from '../store/clickStore'
import { useShallow } from 'zustand/react/shallow'

export default function ObjectDetails() {

    const {objectDetails,objectDetailsPosition,objectDetailsObject,clickType} = useClickStore(useShallow((state)=>({
        objectDetails:state.objectDetails,
        objectDetailsPosition:state.objectDetailsPosition,
        objectDetailsObject:state.objectDetailsObject,
        clickType:state.clickType,
    })))

    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
      // Toggle the visibility of the details panel
    const toggleDetailsVisibility = () => {
        setIsDetailsVisible(!isDetailsVisible);
    };

    useEffect(()=>{
        console.log('object details changed',objectDetails)
    },[objectDetails])


  return (
     <div className="absolute top-40 right-4" >
        
       {/* Toggle Button */}
       {clickType === 'info' &&(<button
        onClick={toggleDetailsVisibility}
        className={`${isDetailsVisible?'bg-blue-500':'bg-gray-500'} text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-gray-600`}
      >
        ℹ️
      </button>)}

      {/* Dropdown Menu */}
      {/* Details Panel */}
      {clickType === 'info' && objectDetailsObject && objectDetailsObject.name !== 'bm' && objectDetails && isDetailsVisible && (
        <div className="absolute right-0 mt-2 bg-white p-1 rounded-lg shadow-lg flex flex-col items-center space-y-2 z-50">
          <div className="objectDetails bg-white p-4 rounded shadow-lg">
            <div className="detailsHeader font-bold mb-2">{objectDetails.Name}</div>
            <table className="detailsTable w-full">
              <thead>
                <tr>
                  <th className="text-left p-1 border-b">Name</th>
                  <th className="text-left p-1 border-b">Value</th>
                </tr>
              </thead>
              <tbody>
                {
                    objectDetails? 
                        Object.entries(objectDetails).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{value}</td>
                        </tr>
                        ))
                        :
                        <></>
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
