export const ModelListPK = [
    {
        'group':'Pickstone',
        'name':'Google',
        'models':
        [
            {name:'SATELLITE',url:'./models/pickstone/google/pk_google-transformed.glb'},
        ]   

    },
    {
        'group':'Pickstone',
        'name':'Pits',
        'models':
        [
            {name:'PK_PIT',url:'./models/pickstone/pits/pk_pit-transformed.glb'},
            {name:'PK_PIT_DESIGN',url:'./models/pickstone/pits/PK_PIT_DESIGN_280524-transformed.glb'},
            ]   

    },
    {
        'group':'Pickstone',
        'name':'Development.SCAN_SIMPLIFIED',
        'models':
        [
            // {name:'2L_SCAN_Simplified',url:'./models/pickstone/scan/2L_SCAN_CE-transformed.glb'},
            // {name:'3L_SCAN_Simplified',url:'./models/pickstone/scan/3L_SCAN_CE-transformed.glb'},
            // {name:'4L_SCAN_Simplified',url:'./models/pickstone/scan/4L_SCAN_CE-transformed.glb'},
            // {name:'5L_SCAN_Simplified',url:'./models/pickstone/scan/5L_SCAN_CE-transformed.glb'},
            // {name:'6L_SCAN_Simplified',url:'./models/pickstone/scan/6L_SCAN_CE-transformed.glb'},
            // {name:'FullScan',url:'./models/pickstone/scan/PICKSTONE_SURVEYS_SCANS.glb'},
            // {name:'FullScan1',url:'./models/pickstone/scan/PICKSTONE_SURVEYS_SCANS1.glb'},
            {name:'1L_STOPE',url:'./models/pickstone/scan/APRIL2024/1L_STOPEo.glb'},
            {name:'2L_SCAN',url:'./models/pickstone/scan/APRIL2024/2L_SCANo.glb'},
            {name:'2L_STOPE',url:'./models/pickstone/scan/APRIL2024/2L_STOPEo.glb'},
            {name:'2L_STOPE01',url:'./models/pickstone/scan/APRIL2024/2L_STOPE01o.glb'},
            {name:'2L_WINZE',url:'./models/pickstone/scan/APRIL2024/2L_WINZEo.glb'},
            {name:'3L_SCAN',url:'./models/pickstone/scan/APRIL2024/3L_SCANo.glb'},
            {name:'3L_STOPE',url:'./models/pickstone/scan/APRIL2024/3L_STOPEo.glb'},
            {name:'3L_STOPE01',url:'./models/pickstone/scan/APRIL2024/3L_STOPE01o.glb'},
            {name:'3L_STOPE02',url:'./models/pickstone/scan/APRIL2024/3L_STOPE02o.glb'},
            {name:'3L_STOPE03',url:'./models/pickstone/scan/APRIL2024/3L_STOPE03o.glb'},
            {name:'3L_STOPE04',url:'./models/pickstone/scan/APRIL2024/3L_STOPE04o.glb'},
            {name:'4L_SCAN',url:'./models/pickstone/scan/APRIL2024/4L_SCANo.glb'},
            {name:'4L_SL_SCAN',url:'./models/pickstone/scan/APRIL2024/4L_SL_SCANo.glb'},
            {name:'5L_SCAN',url:'./models/pickstone/scan/APRIL2024/5L_SCANo.glb'},
            {name:'6L_SCAN',url:'./models/pickstone/scan/APRIL2024/6L_SCANo.glb'},
            {name:'7L_SCAN',url:'./models/pickstone/scan/APRIL2024/7L_SCANo.glb'},
            {name:'SCANS',url:'./models/pickstone/scan/PICKSTONE_SURVEYS_SCANS2.glb'},
        ]   

    },
    {
        'group':'Pickstone',
        'name':'Development.RTZ',
        'models':
        [
            {name:'RTZ_SHAFTS',url:'./models/pickstone/rtz/PK_UG_MINED_OUT_SHAFTS-transformed.glb'},
            {name:'RTZ_DRIVES',url:'./models/pickstone/rtz/PK_UG_MINED_OUT_LEVELS_UPDATE_140224-transformed.glb'},
            {name:'RTZ_STOPES',url:'./models/pickstone/rtz/PK_UG_MINED_OUT_STOPES1-transformed.glb'},
            {name:'RTZ_BOXES',url:'./models/pickstone/rtz/PK_UG_BOXES-transformed.glb'},
            {name:'RTZ_GEO',url:'./models/pickstone/rtz/PK_UG_GEO-transformed.glb'},
        ]   

    },
    {
        'group':'Pickstone',
        'name':'Development.Survey',
        'models':
        [
            {name:'PK_STOPES_0223',url:'./models/pickstone/survey/PK_UG_MINED_OUT_STOPES_2023-transformed.glb'},
            {name:'PK_DRIVES_0423',url:'./models/pickstone/survey/PK_SURV_DRV_0423-transformed.glb'},
            {name:'PK_EOM_1122',url:'./models/pickstone/survey/PK_EOM_1122-transformed.glb'},
            {name:'PK_EOM_1222',url:'./models/pickstone/survey/PK_EOM_1222-transformed.glb'},
            {name:'PK_EOM_0123',url:'./models/pickstone/survey/PK_EOM_0123-transformed.glb'},
            {name:'PK_EOM_0223',url:'./models/pickstone/survey/PK_EOM_0223-transformed.glb'},
            {name:'PK_EOM_0323',url:'./models/pickstone/survey/PK_EOM_0323-transformed.glb'},
            {name:'PK_EOM_0423',url:'./models/pickstone/survey/PK_EOM_0423-transformed.glb'},
            {name:'PK_EOM_0523',url:'./models/pickstone/survey/PK_EOM_0523-transformed.glb'},
            {name:'PK_EOM_1123',url:'./models/pickstone/survey/PK_EOM_1123-transformed.glb'},
            {name:'SURVEY_0724',url:'./models/pickstone/survey/SURVEY_0724.glb'},
        ]   

    },
    {
        'group':'Pickstone',
        'name':'Development.PLAN',
        'models':
        [
            {name:'DRILLING_TARGETS',url:'./models/pickstone/plan/DRILLING_TRAGETS.glb'},
            {name:'PK_PLAN_0423',url:'./models/pickstone/plan/PK_Plan_0423-transformed.glb'},
            {name:'PK_PLAN_0523',url:'./models/pickstone/plan/PK_Plan_0523-transformed.glb'},
            {name:'PK_PLAN_0623',url:'./models/pickstone/plan/PK_Plan_0623-transformed.glb'},
            {name:'PK_PLAN_0723',url:'./models/pickstone/plan/PK_Plan_0723-transformed.glb'},
            {name:'PK_PLAN_0923',url:'./models/pickstone/plan/PK_Plan_0923-transformed.glb'},
            {name:'PK_PLAN_1023',url:'./models/pickstone/plan/PK_Plan_1023-transformed.glb'},
        ]   

    },
    {
        'group':'Pickstone',
        'name':'Development.LOM',
        'models':
        [
            {name:'LOM_DEV',url:'./models/pickstone/plan/LOM_DEV-transformed.glb'},
            {name:'LOM_STOPES',url:'./models/pickstone/plan/LOM_STOPES-transformed.glb'},
            {name:'STOPES_P1',url:'./models/pickstone/plan/PK_UG_PLANNED_STOPES_P1-transformed.glb'},
            {name:'STOPES_P2',url:'./models/pickstone/plan/PK_UG_PLANNED_STOPES_P2-transformed.glb'},
            {name:'DECLINE_5L',url:'./models/pickstone/plan/PK_UG_5L_Decline-transformed.glb'},
            {name:'LOBE',url:'./models/pickstone/plan/LOBE.glb'},
        ]   

    },

    {
        'group':'Pickstone',
        'name':'Geology.ConceptualDesign',
        'models':
        [
            {name:'PE_ADIT',url:'./models/pickstone/plan/peerless_adit.glb'},
            {name:'PLANNED_CUBBIES',url:'./models/pickstone/tendai_model/6L_Cubbies-transformed.glb'},
            {name:'6L_DECLINE',url:'./models/pickstone/tendai_model/6L_Decline-transformed.glb'},
            {name:'CH_MODEL',url:'./models/pickstone/tendai_model/concession_model-transformed.glb'},
            {name:'CH_MODEL_B',url:'./models/pickstone/tendai_model/concession_model_B-transformed.glb'},
            {name:'DH_MODEL',url:'./models/pickstone/tendai_model/duchess_model-transformed.glb'},
            {name:'DH_MODEL_B',url:'./models/pickstone/tendai_model/duchess_model_B-transformed.glb'},
            {name:'PK_MODEL',url:'./models/pickstone/tendai_model/pickstone_model-transformed.glb'},
            {name:'PK_MODEL_B',url:'./models/pickstone/tendai_model/pickstone_model_B-transformed.glb'},

            
            {name:'DGM_OREBLOCKS',url:'./models/pickstone/geological_models/DGM_OREBLOCKS.glb'},

            {name:'DuchessOrebody0824',url:'./models/pickstone/tendai_model/DuchessOrebody_050924.glb'},
            {name:'PickstoneOrebody0824',url:'./models/pickstone/tendai_model/PickstoneOrebody_050924.glb'},
            {name:'PKHGC1Orebody0824',url:'./models/pickstone/tendai_model/PKHGC1Orebody_050924.glb'},
            {name:'PKHGC2Orebody0824',url:'./models/pickstone/tendai_model/PKHGC2Orebody_050924.glb'}
           // {name:'PK_OREBODY_TM',url:'./models/gltfs/TECT/PK_OREBODY_TM-transformed.glb'}, 
           // {name:'Development',url:'.models/pickstone/breckridge_models/Development-transformed.glb'}	  
        ]   

    },

    {
        'group':'Pickstone',
        'name':'PKGeology.TectModels',
        'models':
        [
            { name: 'PK_BIF', url: './models/pickstone/tect/PK_BIF-transformed.glb' },
            { name: 'PK_BLACKSHALE', url: './models/pickstone/tect/PK_BLACKSHALE-transformed.glb' },
            { name: 'PK_GRADESHELL_N1', url: './models/pickstone/tect/PK_GRADESHELL_N1_-transformed.glb' },
            { name: 'PK_GRADESHELL_N2', url: './models/pickstone/tect/PK_GRADESHELL_N2_-transformed.glb' },
            { name: 'PK_GRADESHELL_S1', url: './models/pickstone/tect/PK_GRADESHELL_S1_-transformed.glb' },
            { name: 'PK_GRADESHELL_S2', url: './models/pickstone/tect/PK_GRADESHELL_S2_-transformed.glb' },
            { name: 'PK_OVB', url: './models/pickstone/tect/PK_OVB-transformed.glb' },
            { name: 'PK_TALC', url: './models/pickstone/tect/PK_TALC-transformed.glb' },
            { name: 'PK_VOLC', url: './models/pickstone/tect/PK_VOLC-transformed.glb' },
        ]   

    },

    {
        'group':'Pickstone',
        'name':'Geology.GeologicalShoots',
        'models':
        [
            {name:'Concession_Shoot',url:'./models/pickstone/breckridge_models/Concession_Shoot-transformed.glb'},
            {name:'Dolerite_Dyke',url:'./models/pickstone/breckridge_models/Dolerite_Dyke-transformed.glb'},
            {name:'Mombe_Shoot',url:'./models/pickstone/breckridge_models/Mombe_Shoot-transformed.glb'},
            {name:'Pickstone_Shoot',url:'./models/pickstone/breckridge_models/Pickstone_Shoot-transformed.glb'},
            {name:'Venning_Shoot',url:'./models/pickstone/breckridge_models/Venning_Shoot-transformed.glb'}
                ]   

    },

    {
        'group':'Pickstone',
        'name':'Geology.GeologicalModels',
        'models':
        [   
            {name:'PK_CONC_REEF',url:'./models/pickstone/geological_models/ConcessionModel.glb'},
            {name:'PK_GEO_HG',url:'./models/pickstone/geological_models/PK_UG_GEO_HG-transformed.glb'},
            {name:'PK_GEO_LG',url:'./models/pickstone/geological_models/PK_UG_GEO_LG-transformed.glb'},
            {name:'PK_MAINREEF',url:'./models/pickstone/geological_models/PK_UG_GEO_MR-transformed.glb'},
            {name:'PickstoneOrebody111024',url:'./models/pickstone/geological_models/PickstoneOrebody111024.glb'},
            {name:'ConcessionOrebody111024',url:'./models/pickstone/geological_models/ConcessionOrebody111024.glb'},
                ]   

    },

    {
        'group':'Duchess',
        'name':'Duchess.RollerTrend',
        'models':
        [   
            {name:'RollerTrend',url:'./models/pickstone/duchess/roller_trend.glb'},
            {name:'Reef',url:'./models/pickstone/duchess/roller_trend_reef.glb'},
                ]   

    },
    
    {
        'group':'Peerless',
        'name':'Survey',
        'models':
        [   
            {name:'PE_PIT',url:'./models/peerless/PE_PIT.glb'},
            {name:'PE_DEV',url:'./models/peerless/PE_UG_Dev.glb'},
                ]   

    },

    {
        'group':'Peerless',
        'name':'PEGeology.Breckridge',
        'models':
        [   
            {name:'PE_OREBODY',url:'./models/peerless/PE_OREBODY1.glb'},
                ]   

    },

    {
        'group':'Peerless',
        'name':'PEGeology.TectModels',
        'models':
        [   
            {name:'PE_BIF',url:'./models/peerless/TECT/PE_BIF.glb'},
            {name:'PE_BLACKSHALE',url:'./models/peerless/TECT/PE_BLACKSHALE.glb'},
            {name:'PE_GRADESHALE',url:'./models/peerless/TECT/PE_GRADESHELL.glb'},
            {name:'PE_OVB',url:'./models/peerless/TECT/PE_OVB.glb'},
            {name:'PE_TALC',url:'./models/peerless/TECT/PE_TALC.glb'},
            {name:'PE_VOLC',url:'./models/peerless/TECT/PE_VOLC.glb'},
                ]   

    } ,
    {
        'group':'Holes',
        'name':'Holes',
        'models':
        [
            {name:'PKUG_DD',url:'holes' , dataset:'BRK_PK_RES_UG', holetype:'DD'},
            {name:'DEEPS',url:'holes' , dataset:'PK_Conceptual', holetype:'DD_SFN_DEFLECTION'},
            ]   

    }
    
]