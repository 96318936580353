import React, { useRef, useState, useEffect } from 'react';

const Scribble = ({ isScribbleMode, selectedColor, onColorChange }) => {
  const canvasRef = useRef(null);
  const [lines, setLines] = useState([]); // Stores all lines and shapes
  const [drawingMode, setDrawingMode] = useState('freehand'); // Modes: 'freehand', 'oval', 'rectangle'
  const isDrawing = useRef(false);
  const startX = useRef(0);
  const startY = useRef(0);

  const colors = ['black', 'red', 'blue', 'green', 'orange'];

  // Clear the canvas
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    setLines([]);
  };

  // Undo the last line or shape
  const undoLastLine = () => {
    setLines((prevLines) => {
      const newLines = prevLines.slice(0, -1);
      redrawLines(newLines);
      return newLines;
    });
  };

  // Redraw all lines and shapes after undo or clear
  const redrawLines = (lineHistory) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      lineHistory.forEach((line) => {
        ctx.strokeStyle = line.color;
        ctx.lineWidth = 2;
        ctx.lineJoin = 'round';
        ctx.lineCap = 'round';
        ctx.beginPath();
        if (line.type === 'freehand') {
          line.points.forEach(([x, y], index) => {
            if (index === 0) ctx.moveTo(x, y);
            else ctx.lineTo(x, y);
          });
        } else if (line.type === 'oval') {
          const { x, y, radiusX, radiusY } = line;
          ctx.ellipse(x, y, radiusX, radiusY, 0, 0, Math.PI * 2);
        } else if (line.type === 'rectangle') {
          const { x, y, width, height } = line;
          ctx.rect(x, y, width, height);
        }
        ctx.stroke();
      });
    }
  };

  // Start drawing based on the selected mode
  const startDrawing = (e) => {
    if (!isScribbleMode) return;
    isDrawing.current = true;
    startX.current = e.nativeEvent.offsetX;
    startY.current = e.nativeEvent.offsetY;

    if (drawingMode === 'freehand') {
      const newLine = {
        type: 'freehand',
        color: selectedColor,
        points: [[startX.current, startY.current]]
      };
      setLines((prevLines) => [...prevLines, newLine]);
    }
  };

  // Draw freehand or preview outline for shapes
  const draw = (e) => {
    if (!isScribbleMode || !isDrawing.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const currentX = e.nativeEvent.offsetX;
    const currentY = e.nativeEvent.offsetY;

    // Clear the canvas and redraw everything up to the current state
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    redrawLines(lines);

    ctx.strokeStyle = selectedColor;
    ctx.lineWidth = 2;

    if (drawingMode === 'freehand') {
      const newPoint = [currentX, currentY];
      setLines((prevLines) => {
        const updatedLines = [...prevLines];
        updatedLines[updatedLines.length - 1].points.push(newPoint);
        return updatedLines;
      });

      const lastLine = lines[lines.length - 1] || { points: [] };
      const startPoint = lastLine.points[lastLine.points.length - 1] || newPoint;

      ctx.beginPath();
      ctx.moveTo(startPoint[0], startPoint[1]);
      ctx.lineTo(currentX, currentY);
      ctx.stroke();
    } else if (drawingMode === 'oval') {
      const radiusX = Math.abs(currentX - startX.current) / 2;
      const radiusY = Math.abs(currentY - startY.current) / 2;
      const centerX = (startX.current + currentX) / 2;
      const centerY = (startY.current + currentY) / 2;

      ctx.beginPath();
      ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, Math.PI * 2);
      ctx.stroke();
    } else if (drawingMode === 'rectangle') {
      const width = currentX - startX.current;
      const height = currentY - startY.current;

      ctx.beginPath();
      ctx.rect(startX.current, startY.current, width, height);
      ctx.stroke();
    }
  };

  // Stop drawing and save the shape or line
  const stopDrawing = (e) => {
    if (!isScribbleMode) return;
    isDrawing.current = false;

    const endX = e.nativeEvent.offsetX;
    const endY = e.nativeEvent.offsetY;

    if (drawingMode === 'oval') {
      const radiusX = Math.abs(endX - startX.current) / 2;
      const radiusY = Math.abs(endY - startY.current) / 2;
      const centerX = (startX.current + endX) / 2;
      const centerY = (startY.current + endY) / 2;
      const newOval = { type: 'oval', color: selectedColor, x: centerX, y: centerY, radiusX, radiusY };
      setLines((prevLines) => [...prevLines, newOval]);
    } else if (drawingMode === 'rectangle') {
      const width = endX - startX.current;
      const height = endY - startY.current;
      const newRectangle = { type: 'rectangle', color: selectedColor, x: startX.current, y: startY.current, width, height };
      setLines((prevLines) => [...prevLines, newRectangle]);
    }
  };

  // Clear the canvas and redraw on mode switch
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      clearCanvas();
      redrawLines(lines); // Redraw after clearing
    }
  }, [isScribbleMode, drawingMode]); // Redraw on mode switch

  return (
    <>
      {/* Canvas */}
      {isScribbleMode && (
        <canvas
          ref={canvasRef}
          className="absolute top-0 left-0 w-full h-full pointer-events-auto"
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        ></canvas>
      )}

      {/* Controls for color selection, undo, clear, and mode selection */}
      {isScribbleMode && (
        <div className="absolute top-16 right-4 bg-white p-2 rounded-lg shadow-lg flex flex-col items-center space-y-2 z-50">
          {colors.map((color) => (
            <button
              key={color}
              onClick={() => onColorChange(color)}
              className={`w-6 h-6 rounded-full ${selectedColor === color ? 'ring-2 ring-offset-2' : ''}`}
              style={{
                backgroundColor: color,
                borderColor: color,
                borderWidth: selectedColor === color ? '2px' : '0px'
              }}
            ></button>
          ))}
          <button onClick={undoLastLine} className="w-6 h-6 flex items-center justify-center bg-gray-300 rounded-full shadow">
            ↩️
          </button>
          <button onClick={clearCanvas} className="w-6 h-6 flex items-center justify-center bg-gray-300 rounded-full shadow">
            🗑️
          </button>

          {/* Mode Selection Buttons */}
          <button onClick={() => setDrawingMode('freehand')} className={`w-6 h-6 ${drawingMode === 'freehand' ? 'bg-gray-300' : ''}`}>
            ✏️
          </button>
          <button onClick={() => setDrawingMode('oval')} className={`w-6 h-6 ${drawingMode === 'oval' ? 'bg-gray-300' : ''}`}>
            🟢
          </button>
          <button onClick={() => setDrawingMode('rectangle')} className={`w-6 h-6 ${drawingMode === 'rectangle' ? 'bg-gray-300' : ''}`}>
            ◼️
          </button>
        </div>
      )}
    </>
  );
};

export default Scribble;
