import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Scribble from './Scribble';
import SceneComponent from './SceneComponent';
import { ModelListPK } from "./ModelsList.js"
import LayerControl from './LayerControl'; // Import LayerControl component
import Measurements from '../../clickTools/Measurements.js';
import Info from '../../clickTools/Info.js';
import ColorChange from '../../clickTools/ColorChange.js';
import ObjectDetails from '../../clickTools/ObjectDetails.js';
import PresentationControl from '../../firebase/presentationControl.js';

const Presentation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isScribbleMode, setIsScribbleMode] = useState(false);
  const [selectedColor, setSelectedColor] = useState('black');
  const [previousSlideWasScene, setPreviousSlideWasScene] = useState(false);
  const [showDescription, setShowDescription] = useState(true); // Track description visibility

  
  const [hostedPage, setHostedPage] = useState(0);

   // Automatically close sidebar on medium screens and below
   useEffect(() => {
    handleSlideClick(hostedPage)
  }, [hostedPage]);


  // Automatically close sidebar on medium screens and below
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
        setIsSidebarOpen(false);
      }
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(()=>{
    console.log('presentation refreshed')
  },
    [])


  // Define the slides, with some containing scene-specific content
  const slides = [
    { type: 'image', heading: 'PICKSTONE MINE', content: './presentation/pickstone1.jpg' },
    //{ type: 'video', heading: 'Video Slide', content: './presentation/pp.mp4' },
    //{ type: 'youtube', heading: 'YouTube Slide', content: 'https://www.youtube.com/embed/ABS9ipi-LmU' },
    // {
    //   type: 'scene',
    //   heading: '3D Scene 1',
    //   content: {
    //     visibleObjects: ['eu_pit', 'SouthDump'],
    //     cameraPosition: [5, 500, 5],
    //     lookAt: [0, 0, 0]
    //   }
    // }
    {
      type: 'scene',
      heading: 'Overview',
      content: {
        visibleObjects: ['PK_PIT','PE_PIT','RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','PickstoneOrebody111024','ConcessionOrebody111024','PK_MODEL','PE_GRADESHALE'],
        cameraPosition: [-627, 4000,-388],
        lookAt: [-627, 0, -389],
        description:`<strong>PK Pit</strong>: Depth: 100 meters<strong> • </strong> Associated Orebody: PK Orebody<strong> • </strong> Strike Length: 1 kilometer<strong> • </strong> Reef Width: 3 meters<strong> • </strong> Features: Banded Iron Formation (BIF), Southwell Shaft, Burnett Shaft, Concession Shaft.<br>
                    <strong>PE Pit</strong>: Depth: 150 meters<strong> • </strong> Associated Orebody: PE Orebody<strong> • </strong> Strike Length: 1.5 kilometers<strong> • </strong> Reef Width: 5 meters<strong> • </strong> Features: Concession Hill, Additional Reef Width: 1.5 meters.`
      }
    },{
      type: 'scene',
      heading: 'Underground',
      content: {
        visibleObjects: [
          ,'RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','LOBE','RollerTrend','PE_ADIT'
        ],
        cameraPosition: [-980, 489,3100],
        lookAt: [-579, -278, 137],
        description: `<strong>PK Underground Workings (Known)</strong>: From 1 Level to 14 Level<strong> • </strong> 3 Main Shafts<strong> • </strong> Conceptual Ore Body<strong> • </strong> Key Shafts: Southwell Shaft, Burnett Shaft, Concession Shaft.<br>`
                    //<strong>PK Other Workings</strong>: PK Void Scanning<strong> • </strong> Concession West (voids to the west)<strong> • </strong> PK East to China City (new shafts).`
      }
    },{
      type: 'scene',
      heading: 'Model Comparisons',
      content: {
        visibleObjects: ['PK_PIT','RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','PK_MODEL','PK_GEO_HG'],
        cameraPosition: [754, -297,1604],
        lookAt: [-125, -490, 15],
        description :`<strong>PK HG Ore Body</strong><br><strong>PK Conceptual Orebody</strong>: Open at depth<strong> • </strong><strong>Depth</strong>: 1,500 meters`
      }
    },{
      type: 'scene',
      heading: 'PK Phase1',
      content: {
        visibleObjects: [
          // '1L_STOPE', '2L_SCAN', '2L_STOPE', '2L_STOPE01', '2L_WINZE', '3L_SCAN', '3L_STOPE', '3L_STOPE01', '3L_STOPE02', '3L_STOPE03', '3L_STOPE04', '4L_SCAN', '4L_SL_SCAN', '5L_SCAN', '6L_SCAN', '7L_SCAN',
          'SCANS',
          'SURVEY_0724',
          'PK_PIT','RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','STOPES_P1'],
          cameraPosition: [-537, -251,1248],
          lookAt: [-318, -309, -145],
        description : `<strong>PK Pit</strong><br><strong>Levels</strong>: 2L to 6L<strong> • </strong><strong>Phase</strong>: Phase 1 LOM<strong> • </strong><strong>Completion Date</strong>: June 2025<strong> • </strong><strong>Features</strong>: Lobe Shaft, 5L Conveyor`
      }
    },{
      type: 'scene',
      heading: 'PK Phase2',
      content: {
        visibleObjects: ['PK_PIT','RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','STOPES_P1','STOPES_P2'],
        cameraPosition: [-537, -251,1248],
        lookAt: [-318, -309, -145],
        description : `Southwell Shaft • 10L loading station • Burnett Shaft • PK Underground workings • From 6L to 14 Level • 2 main shafts • Southwell Shaft • Burnett Shaft • Concession Ventilation Shaft • LOM to December 2028`
      }
    },
    // {
    //   type: 'scene',
    //   heading: 'Short Term Plan',
    //   content: {
    //     visibleObjects: ['RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','STOPES_P1','STOPES_P2','DRILLING_TARGETS'],
    //     cameraPosition: [-561, -130,710],
    //     lookAt: [-423, -166, -167],
    //     description : `November 2024: Stopes, Tons and grades, Development metres • December 2024: Stopes, Tons and grades, Development metres • January 2025: Stopes, Tons and grades, Development metres • FY2025: Stopes, Development per month`
    //   }
    // },
    {
      type: 'scene',
      heading: 'Diamond Drilling',
      content: {
        visibleObjects: ['PK_PIT','RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','PKUG_DD'],
        cameraPosition: [-638, -13,23],
        lookAt: [-361, -134, -200],
        description : `PK Underground Diamond Drilling • PKUG Surface Holes • PKUG 4L holes and 6L holes • PKUG Channels`
      }
    },{
      type: 'scene',
      heading: 'New Orebodies',
      content: {
        visibleObjects: ['PK_PIT','RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','PKUG_DD','PickstoneOrebody111024','ConcessionOrebody111024'],
        cameraPosition: [-2053, 753,440],
        lookAt: [-769, -367, -485],
        description : `Duchess West: 0L to 4L, 50,000t of ore • Concession Hill: 0L to 5L, 30,000t of ore • Pickstone trend to Road Shaft or Peerless waste dump • Include new decline to 5L`
      }
    },{
      type: 'scene',
      heading: 'Deeps drilling',
      content: {
        visibleObjects: ['PK_PIT','PE_PIT','RTZ_SHAFTS','RTZ_DRIVES','RTZ_STOPES','PK_MODEL','DEEPS','PLANNED_CUBBIES'],
        cameraPosition: [1125, 654,-675],
        lookAt: [-180, -480, -90],
        description : `Target 16 Level • 1,200m deep hole • Target from 10L`
      }
    },
    { type: 'image', heading: 'END', content: './presentation/end.jpg' }
  ];

  const handleSlideClick = (index) => {
    
    console.log('currentSlide')
    console.log(currentSlide)
    setPreviousSlideWasScene(slides[currentSlide].type === 'scene');
    setCurrentSlide(index);
    setIsScribbleMode(false); // Disable scribble mode on page change
    
    setShowDescription(true)
    


    const newVisibility = { ...layerVisibility };
    
    // Reset all layers to invisible
    Object.keys(newVisibility).forEach((key) => {
      newVisibility[key].visible = false;
    });

    // Enable only the layers specified in the slide's visibleObjects
    const visibleObjects = slides[index]?.content?.visibleObjects || [];
    visibleObjects.forEach((name) => {
      if (newVisibility[name]) {
        newVisibility[name].visible = true;
      }
    });

    setLayerVisibility(newVisibility);
  };

  const handleToggleScribbleMode = () => {
    setIsScribbleMode(!isScribbleMode);
  };

  const renderSlideContent = () => {
    const slide = slides[currentSlide];
    switch (slide.type) {
      case 'image':
        return <img src={slide.content} alt="Slide" className="max-w-full max-h-full mx-auto overflow-hidden" />;

      case 'video':
        return (
          <video controls className="max-w-full max-h-full mx-auto">
            <source src={slide.content} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );

      case 'youtube':
        return (
          <iframe
            width="100%"
            height="100%"
            src={slide.content}
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="max-w-full max-h-full mx-auto"
          ></iframe>
        );

      case 'scene':
        return null; // We render the Canvas separately for scene slides

      default:
        return null;
    }
  };


  //////////LAYER VISIBILITY////////////////
  
  const [layerVisibility, setLayerVisibility] = useState({}); // Manage visibility of each layer
  const [showLayerControl, setShowLayerControl] = useState(false); // Toggle for Layer Control visibility
  
  useEffect(() => {
    const initialVisibility = {};
    ModelListPK.forEach((group) => {
      group.models.forEach((model) => {
        initialVisibility[model.name] = { visible: false, url: model.url };
      });
    });
    setLayerVisibility(initialVisibility);
  }, []);

   // Toggle visibility of layers from LayerControl
  const handleToggleVisibility = (modelName) => {
    setLayerVisibility((prevState) => ({
      ...prevState,
      [modelName]: {
        ...prevState[modelName],
        visible: !prevState[modelName].visible,
      }
    }));
  };

  return (
    <div className="flex h-screen relative">
      {/* Sidebar */}
      <div className={`transition-all duration-300 bg-blue-800 text-white p-1 h-full flex flex-col ${isSidebarOpen ? 'w-1/4 max-w-xs' : 'w-12 lg:w-18 md:w-16'}`}>
        <div className="relative hidden md:flex justify-center mb-4">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="bg-blue-600 text-white p-2 rounded-full shadow-md hover:bg-blue-700 focus:outline-none"
          >
            {isSidebarOpen ? '←' : '→'}
          </button>
        </div>
        <PresentationControl currentSlide={currentSlide} handleSlideClick={setHostedPage} />
        {isSidebarOpen ? (
          <div  className=' h-screen overflow-y-auto p-5' >
            <h2 className="text-sm md:text-xl lg:text-2xl font-bold mb-4 text-center">PICKSTONE</h2>

            <ul >
              {slides.map((slide, index) => (
                <li
                  key={index}
                  className={` flex items-center p-3 cursor-pointer ${index === currentSlide ? 'bg-blue-700' : 'bg-blue-600'} hover:bg-blue-700 transition-all rounded-lg mb-2`}
                  onClick={() => handleSlideClick(index)}
                >
                  <div className={`w-10 h-10  flex items-center justify-center rounded-full shadow text-lg font-bold ${index === currentSlide ? 'bg-blue-400' : 'bg-blue-300'}`}>
                    {index + 1}
                  </div>
                  <span className="text-md font-bold ml-4">{slide.heading}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <ul className="flex flex-col items-center space-y-4 mt-12 overflow-y-auto h-screen" >
            {slides.map((_, index) => (
              <li
                key={index}
                className={`w-6 h-6 md:w-10 d:h-10 flex items-center justify-center cursor-pointer rounded-full shadow text-lg font-bold ${index === currentSlide ? 'bg-blue-400 text-white' : 'bg-blue-300 text-blue-900'}`}
                onClick={() => handleSlideClick(index)}
              >
                {index + 1}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Main Content */}
      <div className="flex-grow flex flex-col transition-all duration-300 relative">
        <div className="flex-grow flex items-center justify-center bg-gray-400 overflow-hidden">
          {renderSlideContent()}
        </div>
        {/* Description Box */}
        {showDescription && slides[currentSlide].content.description && (
          <div className="absolute bottom-14 left-0 w-full bg-gray-100 p-4 shadow-lg flex justify-between items-center text-gray-700 z-50">
            <p dangerouslySetInnerHTML={{ __html: slides[currentSlide].content.description }}></p>
            <button
              onClick={() => setShowDescription(false)}
              className="text-gray-500 hover:text-gray-700 font-bold text-sm w-fit"
            >
              &times;
            </button>
          </div>
        )}


        {slides[currentSlide].type === 'scene' && (
          <>

            {/* Toggle Button for Layer Control */}
             <button
              onClick={() => setShowLayerControl(!showLayerControl)}
              className = {`absolute top-16 right-4  ${showLayerControl?'bg-blue-500':'bg-gray-500'} text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-blue-600 z-50`}
              >
              🗂️
            </button>
           

            {/* Layer Control Panel */}
            {showLayerControl && (
              <LayerControl
                modelList={ModelListPK}
                layerVisibility={layerVisibility}
                onToggleVisibility={handleToggleVisibility}
              />
            )}

       
            <Canvas className="absolute inset-0" flat shadows gl={{ antialias: true }} camera={{ position: [0, 7000, 0], near: 0.5, far: 10000, fov: 35 }}>
              <SceneComponent
                currentSlide = {currentSlide}
                layerVisibility={layerVisibility} 
                cameraPosition={slides[currentSlide].content.cameraPosition}
                lookAt={slides[currentSlide].content.lookAt}
                animateCamera={previousSlideWasScene}
              />
            </Canvas>
            
            <Measurements/>
            <ColorChange/>
            <ObjectDetails/>
            <Info/>
          </>



        )}

        {/* Scribble Toggle Button */}
        <button
          onClick={handleToggleScribbleMode}
          className="absolute top-4 right-4 bg-gray-500 text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-gray-600 z-50"
        >
          ✏️
        </button>

        {/* Scribble Component */}
        <Scribble
          isScribbleMode={isScribbleMode}
          selectedColor={selectedColor}
          onColorChange={setSelectedColor}
        />

        

        {/* Footer Navigation */}
        <div className="py-1 bg-blue-200 text-blue-900 flex justify-between items-center px-4">
          <button
            onClick={() => handleSlideClick((currentSlide - 1 + slides.length) % slides.length)}
            className="bg-blue-600 text-white w-fit px-3 py-1 md:px-4 md:py-2 rounded shadow-md hover:bg-blue-700"
          >
            Previous
          </button>
          <span className="text-center text-sm md:text-lg w-fit">
            <div className="w-fit p-2 h-5  md:h-12 flex items-center justify-center rounded-full shadow bg-gray-100 font-bold">
              {currentSlide + 1} / {slides.length}
            </div>
          </span>
          <button
            onClick={() => handleSlideClick((currentSlide + 1) % slides.length)}
            className="bg-blue-600 text-white w-fit px-3 py-1 md:px-4 md:py-2 rounded shadow-md hover:bg-blue-700"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
