import { db } from "./firebaseConfig";
import { nanoid } from "nanoid"; // Install nanoid for unique key generation
import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";

// Function to host a new presentation
export async function hostPresentation(currentSlide) {
  const newKey = nanoid(6); // Generate a unique 6-character key
  console.log(newKey)
  await setDoc(doc(db, "presentations", newKey), {
    page: currentSlide // Initial slide
  });
  return newKey;
}


export async function joinPresentation(key, handleSlideClick) {
  const slideDocRef = doc(db, "presentations", key);

  // Check if the document exists
  const docSnapshot = await getDoc(slideDocRef);
  if (docSnapshot.exists()) {
    console.log("Joined presentation with key:", key);

    // Listen for updates to the page field
    onSnapshot(slideDocRef, (snapshot) => {
      const data = snapshot.data();
      if (data && data.page !== undefined) {
        handleSlideClick(data.page);
      }
    });

    return true
  } else {
    alert("Invalid key. Presentation not found.");
    return false
  }
}
