import React, { useState } from 'react';

const LayerControl = ({ modelList, layerVisibility, onToggleVisibility }) => {
  return (
    <div className="absolute top-0 left-0 bg-white p-4 rounded shadow-md w-fit h-full max-w-xs max-h-screen overflow-y-auto z-50">
      <h2 className="text-xl font-bold mb-4">Layer Control</h2>
      {modelList.map((group, groupIndex) => (
        <CollapsibleGroup
          key={groupIndex}
          group={group}
          layerVisibility={layerVisibility}
          onToggleVisibility={onToggleVisibility}
        />
      ))}
    </div>
  );
};

const CollapsibleGroup = ({ group, layerVisibility, onToggleVisibility }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-2">
      {/* Group Title */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between cursor-pointer bg-gray-300 p-2 rounded"
      >
        <span className="font-semibold">{group.name}</span>
        <span>{isOpen ? '▲' : '▼'}</span>
      </div>

      {/* Models in Group */}
      {isOpen && (
        <div className="ml-4 mt-2">
          {group.models.map((model, modelIndex) => (
            <div key={modelIndex} className="flex items-center mb-1">
              <input
                type="checkbox"
                checked={layerVisibility[model.name]?.visible || false}
                onChange={() => onToggleVisibility(model.name)}
                className="mr-2"
              />
              <label>{model.name}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LayerControl;
