import { useGLTF } from "@react-three/drei"
import { Suspense } from "react";


const LoadModel = ({name,url,visible})=> {
    const model = useGLTF(url)
    return (
        <Suspense fallback={null} key={name}>
            {visible&& <primitive object={model.scene}/> }
        </Suspense>
    );
}

export default LoadModel