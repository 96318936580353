import { useEffect, useState } from "react";
import { joinPresentation, hostPresentation } from "./presentationFunctions";
import { db } from "./firebaseConfig";
import { doc, setDoc } from "firebase/firestore";

function PresentationControl({ currentSlide, handleSlideClick }) {
  const [key, setKey] = useState("");
  const [inputKey, setInputKey] = useState(""); // Separate state for input field
  const [user, setUser] = useState('Join');

  const handleJoin = async() => {
    const joined = await joinPresentation(inputKey, handleSlideClick);
    if(!joined) return
    setKey(inputKey); // Set key only when joining
    setUser('Join');
  };

  const handleHost = async () => {
    const newKey = await hostPresentation(currentSlide);
    setKey(newKey); // Set key for hosting
    setInputKey(newKey)
    setUser('Host');
    alert(`Presentation hosted with key: ${newKey}`);
  };

  useEffect(() => {
    // Function to update Firestore whenever currentSlide changes
    const updateSlideInFirestore = async () => {
      try {
        await setDoc(doc(db, "presentations", key), {
          page: currentSlide
        });
        console.log("Updated Firestore with currentSlide:", currentSlide);
      } catch (error) {
        console.error("Error updating Firestore:", error);
      }
    };

    // Update Firestore only if user is hosting
    if (user === 'Host') updateSlideInFirestore();
  }, [currentSlide, key, user]); // Dependency includes key and user

  return (
    <div className="flex flex-col">
      <div className="flex items-center border-b border-teal-500 py-2 space-x-2">
        <input
          type="text"
          placeholder="Enter key to join"
          value={inputKey}
          onChange={(e) => setInputKey(e.target.value)} // Update only inputKey here
          className="flex-1 text-black border rounded px-2 py-1"
        />
        <button
          className="p-2 bg-teal-500 text-white rounded"
          onClick={handleJoin}
        >
          Join
        </button>
        <button
          className={`p-2 rounded ${
            user === 'Host' ? 'bg-gray-400 cursor-not-allowed' : 'bg-teal-500 text-white'
          }`}
          onClick={handleHost}
          disabled={user === 'Host'}
        >
          Host
        </button>
      </div>
      {key && (
        <p className="w-full mt-2 text-teal-700">
          {user === 'Host' ? `Hosting on: ${key}` : `Listening on: ${key}`}
        </p>
      )}
    </div>
  );
}

export default PresentationControl;
