import React, { useEffect,useRef } from 'react';
import { useThree, useFrame} from '@react-three/fiber';
import TWEEN from 'tween'
import { Vector3 } from 'three';
import { OrbitControls } from '@react-three/drei';
import LoadModel from './LoadModel';
import { ModelListPK } from "./ModelsList.js"
import HolesLoader from './HolesLoader.js'
import MeasureLines from '../../clickTools/MeasureLines.js'
import { useClickStore, useInforStore } from '../../store/clickStore.js';
import { useShallow } from 'zustand/react/shallow';
import CameraControl from '../../clickTools/CameraControl.js';

const SceneComponent = ({ layerVisibility, cameraPosition, lookAt, animateCamera ,currentSlide}) => {
  const { camera } = useThree();
  
  const controlRef = useRef()

  const constants_ = [205200,7975000,1180]

  const {setInfo} =    useInforStore(useShallow((state)=>({
      setInfo:state.setInfo
  })))

  const {setClickParams} = useClickStore(useShallow((state)=>({        
      setClickParams:state.setClickParams
  })))
  useEffect(()=>{
      console.log('Experience rerendered')
  },[])

  const manage_click=(e)=>{
      console.log(e,e.point,e.object,e.instanceId)
      setClickParams(e.point,e.object,e.instanceId)
      setInfo(e.object?e.object.name+`   coords:X:${(e.point.x+constants_[0]).toFixed(1)} Y:${(e.point.z-constants_[1]).toFixed(1)} Z:${(e.point.y+constants_[2]).toFixed(1)}`:'...')
      e.stopPropagation()        
  }

  
  useEffect(() => {
    if (animateCamera) {
      // If animation is required, tween the camera position and look-at target
      const endPosition = new Vector3(...cameraPosition);
      const endLookAt = new Vector3(...lookAt);

      new TWEEN.Tween(camera.position)
        .to(endPosition, 5000) // Duration of animation in milliseconds
        .easing(TWEEN.Easing.Cubic.Out) // Easing function for smoothness
        .start();

      new TWEEN.Tween(controlRef.current.target)
        .to(endLookAt, 5000) // Duration should match position tween
        .easing(TWEEN.Easing.Cubic.Out)
        .start();

    } else {
      // If no animation, set immediately
      camera.position.set(...cameraPosition);
      controlRef.current.target.set(...lookAt);
    }
  }, [currentSlide]);

  // Update tweens on each frame
  useFrame(() => {
    TWEEN.update();
    // sconsole.log('camera pos-',camera.position , 'LookAt',controlRef.current.target)
  });

  return (
    <>  
      <directionalLight position={[1,200,-300]} intensity={2.5}/>
      <directionalLight position={[1,200,300]} intensity={2.5}/>
      <ambientLight intensity={1.5}/> 
      <OrbitControls makeDefault ref={controlRef}/>
      <MeasureLines />
      {/* Conditionally render objects based on visibleObjects */}
      <group onClick={(e)=>{ manage_click(e)} } >
      {ModelListPK.map((group) =>
        group.models.map((model) => {
          const modelState = layerVisibility[model.name];
          if (modelState) {
            return (
              (model.url=='holes')?
              <HolesLoader key={model.name} name={model.name} url={'./files/Holes050924.zip'} dataset={model.dataset} holetype={model.holetype} visible={modelState.visible}/>
              :
              <LoadModel key={model.name} name={model.name} url={model.url} visible={modelState.visible}/>
            );
          }
          return null;
        })
      )}
      </group>
      <CameraControl controls={controlRef}/>
      <ambientLight intensity={0.5} />
      <directionalLight position={[5, 5, 5]} intensity={0.5} />    
    </>  
  );
};

export default SceneComponent;
